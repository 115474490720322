/* eslint-disable react-hooks/exhaustive-deps */

import { StorageManager } from '@aws-amplify/ui-react-storage';
import { API, Storage } from 'aws-amplify';
import { phone } from 'phone';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import { AwsGeoAddressField } from '../../components/Address';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { FormError, getTimeZoneFromHere, handleApiError } from '../../helpers';
import { storeShipper } from '../../stores/slice';

export const getShipper = /* GraphQL */ `
  query GetShipper($id: ID!) {
    getShipper(id: $id) {
      id
      name
      alias
      phone
      email
      image
      active
      address {
        address1
        address2
        city
        state
        postalCode
        location {
          latitude
          longitude
          }
      }
    }
  }
`;

export const createShipper = /* GraphQL */ `
  mutation CreateShipper( $input: CreateShipperInput! ) {
    createShipper(input: $input) {
      id
      name
      image
    }
  }
`;

export const updateShipper = /* GraphQL */ `
  mutation UpdateShipper(
    $input: UpdateShipperInput!  ) {
    updateShipper(input: $input) {
      id
      name
      image
    }
  }
`;

const ShipperEditor = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);

  const { shipperGroupId, shipperId } = useParams();

  const [spinner, showSpinner] = useState(false);
  const [errors, setErrors] = useState();

  const [shipper, setShipper] = useState({
    id: uniqid(),
    name: '',
    alias: '',
    email: '',
    phone: '',
    active: true,
    shipperGroupId: shipperGroupId,
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      location: {
        latitude: 0,
        longitude: 0
      }
    },
  });


  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${(shipperGroupId && shipperId) ? `/shipper-group/${shipperGroupId}/shipper/${shipperId}/edit` : `/shipper-group/${shipperGroupId}/shipper/add`}`,
      title: "Shipper Editor"
    })

  }, [])

  useEffect(() => shipperId && getShipperById(), []);

  const getShipperById = () => {
    showSpinner(true);
    API.graphql({ query: getShipper, variables: { id: shipperId } })
      .then((response) => setShipper(response?.data?.getShipper))
      .catch((error) => handleApiError(error))
      .finally(() => showSpinner(false));
  };

  const isFormValid = () => {
    const error = {};
    if (!shipper?.name?.trim()) error['name'] = 'Name is required'
    if (!shipper?.email?.trim()) error['email'] = 'Email is required'
    if (!phone(shipper?.phone?.trim()).isValid) error['phone'] = 'Please enter a valid phone number';
    if (!shipper?.address?.address1?.trim()) error['address1'] = 'Address Line 1 is required';
    if (!shipper?.address?.city?.trim()) error['city'] = 'City is required';
    if (!shipper?.address?.state?.trim()) error['state'] = 'State is required';
    if (!shipper?.address?.postalCode?.trim()) error['postalCode'] = 'Postal code is required';

    setErrors(error);

    return Object.keys(error).length === 0;
  };

  const handleChange = async (e) => setShipper((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  const handleAddress2Change = (e) => setShipper((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value } }));
  const handlePhoneChange = (phone) => setShipper((prevState) => ({ ...prevState, phone: `+${phone}` }));

  const handleAddressSelect = async (address) => {
    let responseAddress = address?.value
    let postalCode = responseAddress?.postalCode?.split('-')[0]
    let newLabel = responseAddress?.label?.split(',')
    newLabel = newLabel[0] + newLabel[1] ? newLabel[1] : ''
    let addressline1 = (responseAddress?.addressNumber ? responseAddress?.addressNumber : "") + " " + (responseAddress?.street ? responseAddress?.street : '')
    let location = {};
    if (responseAddress?.geometry?.point?.length > 0) {
      location.latitude = responseAddress?.geometry?.point[1];
      location.longitude = responseAddress?.geometry?.point[0];
    }

    const carrierAddress = {
      address1: addressline1?.trim(),
      city: responseAddress?.municipality,
      state: responseAddress?.region,
      postalCode,
      location
    };

    let timezone = await getTimeZoneFromHere(responseAddress?.timeZone?.name)
    setShipper({
      ...shipper,
      address: carrierAddress,
      timezone: timezone,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid()) {
      showSpinner(true)
      !shipper.image && delete shipper.image
      if (!shipperId) shipper.active = true;
      const query = shipperId ? updateShipper : createShipper;
      API.graphql({ query: query, variables: { input: shipper } })
        .then((response) => {
          if (shipperId) {
            toast.success(`${shipper?.name} has been updated.`)
            dispatch(storeShipper(response?.data?.updateShipper));
            navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`);
          } else {
            toast.success(`${shipper?.name} has been added.`)
            dispatch(storeShipper(response?.data?.createShipper));
            navigate(`/shipper-group/${shipperGroupId}/shipper/${response?.data?.createShipper?.id}/overview`);
          }
        })
        .catch((error) => handleApiError(error))
        .finally(() => showSpinner(false))
    }
  };

  const processFile = ({ file }) => {
    const ext = file.name.split('.').pop();
    return { file, key: `${uniqid()}.${ext}` };
  };

  const onUploadSuccess = ({ key }) => {
    Storage.get(key).then(url => {
      setShipper(prevAttachment => ({ ...prevAttachment, image: url.split('?')[0] }))
    });
  };

  return (
    <>
      <PageHeader title={myShipperGroup?.name} name={shipper?.name || 'New Shipper'} pageTitle={`${shipperId ? "Edit Shipper" : " Add New Shipper"}`} classname={'container'} ></PageHeader>

      <Spinner display={spinner}>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <FormLabel required={true}>Name</FormLabel>
              <Form.Control type='text' name='name' placeholder='e.g. ABC Specialty Pharmacy' value={shipper?.name} onChange={handleChange} isInvalid={!!errors?.name} />
              <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mt-4'>
              <FormLabel>Alias</FormLabel>
              <Form.Control type='text' name='alias' placeholder='e.g. abc-specialty' value={shipper?.alias} onChange={handleChange} />
            </Form.Group>
            <Row>
              <Col lg={6}>
                <Form.Group className='mt-4'>
                  <FormLabel required={true}>Email</FormLabel>
                  <Form.Control type='email' name='email' placeholder='e.g. john@pharmacy.com' value={shipper?.email} onChange={handleChange} isInvalid={!!errors?.email} />
                  <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className='mt-4'>
                  <FormLabel required={true}>Phone </FormLabel>
                  <Form.Control type='phone' name='phone' className='d-none' placeholder='e.g. 998-776-5543' value={shipper.phone || ''} onChange={handleChange} isInvalid={!!errors?.phone} />
                  <PhoneInput country='us' onlyCountries={['us']} value={shipper.phone} placeholder='e.g. 998-776-5543' onChange={handlePhoneChange} isInvalid={!!errors?.phone} />
                  <Form.Control.Feedback type='invalid'>{errors?.phone}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={6}>
                <Form.Group>
                  <FormLabel required={true}>Address Line 1 </FormLabel>
                  <AwsGeoAddressField value={shipper?.address?.address1} onChange={handleAddressSelect} error={errors?.address1} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <FormLabel >Address Line 2</FormLabel>
                  <Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={shipper?.address?.address2} onChange={handleAddress2Change} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>City</FormLabel>
                  <Form.Control type='text' name='city' placeholder='e.g. Seattle' value={shipper?.address?.city} onChange={handleChange} disabled />
                </Form.Group>
                <FormError error={errors?.city} />
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>State</FormLabel>
                  <Form.Control type='text' name='state' placeholder='e.g. WA' value={shipper?.address?.state} onChange={handleChange} disabled />
                </Form.Group>
                <FormError error={errors?.state} />
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>Postal Code</FormLabel>
                  <Form.Control type='text' name='postalCode' placeholder='e.g. 98105' value={shipper?.address?.postalCode} onChange={handleChange} disabled />
                </Form.Group>
                <FormError error={errors?.postalCode} />
              </Col>

              <Col lg={12} className='mt-5'>
                <FormLabel>Upload Logo</FormLabel>
                <StorageManager
                  acceptedFileTypes={['.png', '.jpeg', '.jpg']}
                  accessLevel='public' maxFileCount={3}
                  showImages={false} path={`Shipper/${shipper?.id}/`}
                  processFile={processFile} onUploadSuccess={onUploadSuccess}
                />

              </Col>

            </Row>
            <hr />
            <Button type='submit' className='btn btn-dark'>Continue</Button>
            <Link to={shipperId ? `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview` : `/shipper-group/${shipperGroupId}/shipper`} className='btn btn-link text-muted' >Cancel</Link>
          </Form>
        </Container>
      </Spinner>
    </>
  )
}

export default ShipperEditor;
